const path = {
  home: '/',
  dashboard: '/dashboards/modern',
  categories: '/categories',
  projects: '/projects',
  refs: '/refs',
} as const;

export const unAuthRouters = ['/', '/home', '/login', '/register'];

export default path;
