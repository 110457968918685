import { createAction, createReducer } from '@reduxjs/toolkit';

interface CommonState {
  isLoadingPage: boolean;
  isPlayingGame: boolean;
  pageSiteQuery: {
    title?: string;
    description?: string;
    keywords?: string;
    author?: string | null;
    is_user_join?: boolean;
    is_open_type?: boolean;
    captcha?: boolean;
    enable_consultation?: boolean;
    require_read_note?: boolean;
    __typename?: string;
  };
  isMaintenance: boolean;
}

const initialState: CommonState = {
  isLoadingPage: false,
  isPlayingGame: false,
  pageSiteQuery: {},
  isMaintenance: false,
};

export const setIsLoadingPage = createAction('loading', function (isLoadingPage: boolean) {
  return {
    payload: {
      isLoadingPage: isLoadingPage,
    },
  };
});

export const setPageSiteQuery = createAction(
  'setPageSiteQuery',
  function (pageSiteQuery: CommonState['pageSiteQuery']) {
    return {
      payload: {
        pageSiteQuery,
      },
    };
  },
);

export const setShowMaintenance = createAction(
  'setShowMaintenance',
  function (isMaintenance: boolean) {
    return {
      payload: {
        isMaintenance: isMaintenance,
      },
    };
  },
);

export const setIsPlayingGame = createAction('setIsPlayingGame', function (isPlayingGame: boolean) {
  return {
    payload: {
      isPlayingGame: isPlayingGame,
    },
  };
});

const commonReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setIsLoadingPage, (state, action) => {
      state.isLoadingPage = action.payload.isLoadingPage;
    })
    .addCase(setPageSiteQuery, (state, action) => {
      state.pageSiteQuery = action.payload.pageSiteQuery;
    })
    .addCase(setIsPlayingGame, (state, action) => {
      state.isPlayingGame = action.payload.isPlayingGame;
    })
    .addCase(setShowMaintenance, (state, action) => {
      state.isMaintenance = action.payload.isMaintenance;
    });
});

export default commonReducer;
