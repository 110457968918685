import { createAction, createReducer } from '@reduxjs/toolkit';

export interface RefState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name: string;
  destinationUrl: string;
  createForm: {
    isOpen: boolean;
  };
}

const initialState: RefState = {
  name: '',
  destinationUrl: '',
  createForm: {
    isOpen: false,
  },
};

export const setOpenCreateForm = createAction('setOpenCreateForm', function (data: boolean) {
  return {
    payload: data,
  };
});

const refReducer = createReducer(initialState, (builder) => {
  builder.addCase(setOpenCreateForm, (state, action) => {
    state.createForm.isOpen = action.payload;
  });
});

export default refReducer;
