import React, { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import path from 'src/constants/path';
import ProtectedRoute from './ProtectedRoute';
import AuthLayout from 'src/layouts/auth/AuthLayout';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

/* ****Pages***** */
const Login = Loadable(lazy(() => import('../views/auth/Login')));
const Register = Loadable(lazy(() => import('../views/auth/Register')));
const ModernDashboard = Loadable(lazy(() => import('../views/dashboard/Modern')));
const Projects = Loadable(lazy(() => import('../views/projects')));
const Refs = Loadable(lazy(() => import('../views/refs')));
const Categories = Loadable(lazy(() => import('../views/categories')));

const Router = [
  {
    path: '',
    element: <ProtectedRoute />,
    children: [
      {
        element: (
          <FullLayout>
            <Outlet />
          </FullLayout>
        ),
        children: [
          { path: path.home, element: <Navigate to={path.dashboard} /> },
          { path: path.dashboard, exact: true, element: <ModernDashboard /> },
          { path: path.projects, exact: true, element: <Projects /> },
          { path: path.refs, exact: true, element: <Refs /> },
          { path: path.categories, exact: true, element: <Categories /> },
        ],
      },
      //   {
      //     path: "/dashboards/ecommerce",
      //     exact: true,
      //     element: <EcommerceDash />,
      //   },
      //   { path: "/apps/contacts", element: <Contacts /> },
      //   { path: "/apps/blog/posts", element: <Blog /> },
      //   { path: "/apps/blog/detail/:id", element: <BlogDetail /> },
      //   { path: "/apps/chats", element: <Chats /> },
      //   { path: "/apps/email", element: <Email /> },
      //   { path: "/apps/notes", element: <Notes /> },
      //   { path: "/apps/tickets", element: <Tickets /> },
      //   { path: "/apps/ecommerce/shop", element: <Ecommerce /> },
      //   {
      //     path: "/apps/ecommerce/eco-product-list",
      //     element: <EcomProductList />,
      //   },
      //   {
      //     path: "/apps/ecommerce/eco-checkout",
      //     element: <EcomProductCheckout />,
      //   },
      //   { path: "/apps/ecommerce/detail/:id", element: <EcommerceDetail /> },
      //   { path: "/apps/followers", element: <Followers /> },
      //   { path: "/apps/friends", element: <Friends /> },
      //   { path: "/apps/gallery", element: <Gallery /> },
      //   { path: "/user-profile", element: <UserProfile /> },
      //   { path: "/apps/calendar", element: <Calendar /> },
      //   { path: "/ui-components/alert", element: <MuiAlert /> },
      //   { path: "/ui-components/accordion", element: <MuiAccordion /> },
      //   { path: "/ui-components/avatar", element: <MuiAvatar /> },
      //   { path: "/ui-components/chip", element: <MuiChip /> },
      //   { path: "/ui-components/dialog", element: <MuiDialog /> },
      //   { path: "/ui-components/list", element: <MuiList /> },
      //   { path: "/ui-components/popover", element: <MuiPopover /> },
      //   { path: "/ui-components/rating", element: <MuiRating /> },
      //   { path: "/ui-components/tabs", element: <MuiTabs /> },
      //   { path: "/ui-components/tooltip", element: <MuiTooltip /> },
      //   { path: "/ui-components/transfer-list", element: <MuiTransferList /> },
      //   { path: "/ui-components/typography", element: <MuiTypography /> },
      //   { path: "/pages/casl", element: <RollbaseCASL /> },
      //   { path: "/pages/treeview", element: <Treeview /> },
      //   { path: "/pages/pricing", element: <Pricing /> },
      //   { path: "/pages/faq", element: <Faq /> },
      //   { path: "/pages/account-settings", element: <AccountSetting /> },
      //   { path: "/tables/basic", element: <BasicTable /> },
      //   { path: "/tables/enhanced", element: <EnhanceTable /> },
      //   { path: "/tables/pagination", element: <PaginationTable /> },
      //   { path: "/tables/fixed-header", element: <FixedHeaderTable /> },
      //   { path: "/tables/collapsible", element: <CollapsibleTable /> },
      //   { path: "/tables/search", element: <SearchTable /> },
      //   {
      //     path: "/forms/form-elements/autocomplete",
      //     element: <MuiAutoComplete />,
      //   },
      //   { path: "/forms/form-elements/button", element: <MuiButton /> },
      //   { path: "/forms/form-elements/checkbox", element: <MuiCheckbox /> },
      //   { path: "/forms/form-elements/radio", element: <MuiRadio /> },
      //   { path: "/forms/form-elements/slider", element: <MuiSlider /> },
      //   { path: "/forms/form-elements/date-time", element: <MuiDateTime /> },
      //   { path: "/forms/form-elements/switch", element: <MuiSwitch /> },
      //   { path: "/forms/form-elements/switch", element: <MuiSwitch /> },
      //   { path: "/forms/form-layouts", element: <FormLayouts /> },
      //   { path: "/forms/form-custom", element: <FormCustom /> },
      //   { path: "/forms/form-wizard", element: <FormWizard /> },
      //   { path: "/forms/form-validation", element: <FormValidation /> },
      //   { path: "/forms/form-horizontal", element: <FormHorizontal /> },
      //   { path: "/forms/form-vertical", element: <FormVertical /> },
      //   { path: "/forms/quill-editor", element: <QuillEditor /> },
      //   { path: "/charts/area-chart", element: <AreaChart /> },
      //   { path: "/charts/line-chart", element: <LineChart /> },
      //   { path: "/charts/gredient-chart", element: <GredientChart /> },
      //   { path: "/charts/candlestick-chart", element: <CandlestickChart /> },
      //   { path: "/charts/column-chart", element: <ColumnChart /> },
      //   { path: "/charts/doughnut-pie-chart", element: <DoughnutChart /> },
      //   { path: "/charts/radialbar-chart", element: <RadialbarChart /> },
      //   { path: "/widgets/cards", element: <WidgetCards /> },
      //   { path: "/widgets/banners", element: <WidgetBanners /> },
      //   { path: "/widgets/charts", element: <WidgetCharts /> },
      //   { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
  {
    path: '',
    element: <AuthLayout />,
    children: [
      { path: '/login', element: <Login /> },
      { path: '/register', element: <Register /> },
    ],
  },
  {
    path: '/',
    element: <BlankLayout />,
    // children: [
    //   { path: "/auth/404", element: <Error /> },
    //   { path: "/auth/login", element: <Login /> },
    //   { path: "/auth/login2", element: <Login2 /> },
    //   { path: "/auth/register", element: <Register /> },
    //   { path: "/auth/register2", element: <Register2 /> },
    //   { path: "/auth/forgot-password", element: <ForgotPassword /> },
    //   { path: "/auth/forgot-password2", element: <ForgotPassword2 /> },
    //   { path: "/auth/two-steps", element: <TwoSteps /> },
    //   { path: "/auth/two-steps2", element: <TwoSteps2 /> },
    //   { path: "/auth/maintenance", element: <Maintenance /> },
    //   { path: "/landingpage", element: <Landingpage /> },
    //   { path: "*", element: <Navigate to="/auth/404" /> },
    // ],
  },
];

export default Router;
