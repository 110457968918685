import { createSlice, current } from '@reduxjs/toolkit';

interface StateType {
  activeDir?: string | any;
  activeMode?: string; // This can be light or dark
  activeTheme?: string; // BLUE_THEME, GREEN_THEME, BLACK_THEME, PURPLE_THEME, ORANGE_THEME
  SidebarWidth?: number;
  MiniSidebarWidth?: number;
  TopbarHeight?: number;
  isCollapse?: boolean;
  isLayout?: string;
  isSidebarHover?: boolean;
  isMobileSidebar?: boolean;
  isHorizontal?: boolean;
  isLanguage?: string;
  isCardShadow?: boolean;
  borderRadius?: number | any;
}

const currentTheme = JSON.parse(localStorage.getItem('theme') || '{}');

const toggleTheme = (state: StateType) => {
  localStorage.setItem('theme', JSON.stringify(state));
};

const initialState: StateType = {
  activeDir: currentTheme.activeDir ?? 'ltr',
  activeMode: currentTheme.activeMode ?? 'light', // This can be light or dark
  activeTheme: currentTheme.activeTheme ?? 'BLUE_THEME', // BLUE_THEME, GREEN_THEME, BLACK_THEME, PURPLE_THEME, ORANGE_THEME
  SidebarWidth: currentTheme.SidebarWidth ?? 270,
  MiniSidebarWidth: currentTheme.MiniSidebarWidth ?? 87,
  TopbarHeight: currentTheme.TopbarHeight ?? 70,
  isLayout: currentTheme.isLayout ?? 'boxed', // This can be full or boxed
  isCollapse: currentTheme.isCollapse ?? false, // to make sidebar Mini by default
  isSidebarHover: currentTheme.isSidebarHover ?? false,
  isMobileSidebar: currentTheme.isMobileSidebar ?? false,
  isHorizontal: currentTheme.isHorizontal ?? false,
  isLanguage: currentTheme.isLanguage ?? 'en',
  isCardShadow: currentTheme.isCardShadow ?? true,
  borderRadius: currentTheme.borderRadius ?? 7,
};

export const CustomizerSlice = createSlice({
  name: 'customizer',
  initialState,
  reducers: {
    setTheme: (state: StateType, action) => {
      state.activeTheme = action.payload;
      toggleTheme(state);
    },
    setDarkMode: (state: StateType, action) => {
      state.activeMode = action.payload;
      toggleTheme(state);
    },
    setDir: (state: StateType, action) => {
      state.activeDir = action.payload;
      toggleTheme(state);
    },
    setLanguage: (state: StateType, action) => {
      state.isLanguage = action.payload;
      toggleTheme(state);
    },
    setCardShadow: (state: StateType, action) => {
      state.isCardShadow = action.payload;
      toggleTheme(state);
    },
    toggleSidebar: (state) => {
      state.isCollapse = !state.isCollapse;
      toggleTheme(state);
    },
    hoverSidebar: (state: StateType, action) => {
      state.isSidebarHover = action.payload;
      toggleTheme(state);
    },
    toggleMobileSidebar: (state) => {
      state.isMobileSidebar = !state.isMobileSidebar;
      toggleTheme(state);
    },
    toggleLayout: (state: StateType, action) => {
      state.isLayout = action.payload;
      toggleTheme(state);
    },
    toggleHorizontal: (state: StateType, action) => {
      state.isHorizontal = action.payload;
      toggleTheme(state);
    },
    setBorderRadius: (state: StateType, action) => {
      state.borderRadius = action.payload;
      toggleTheme(state);
    },
  },
});

export const {
  setTheme,
  setDarkMode,
  setDir,
  toggleSidebar,
  hoverSidebar,
  toggleMobileSidebar,
  toggleLayout,
  setBorderRadius,
  toggleHorizontal,
  setLanguage,
  setCardShadow,
} = CustomizerSlice.actions;

export default CustomizerSlice.reducer;
