/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Navigate } from 'react-router-dom';
import { config } from 'src/constants/config';
import { HttpStatusCode } from 'src/constants/httpStatusCode.enum';
import path, { unAuthRouters } from 'src/constants/path';
import { logout } from '../stores/auth.reducer';
import { setShowMaintenance } from '../stores/common.reducer';
import { clearLS, getAccessTokenFromLS } from './auth';
import { store } from './store';

const httpLink = createHttpLink({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  uri: ({ getContext }) => {
    const { apiName } = getContext();

    return config.baseUrl + '/' + apiName;
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const { statusCode }: any = networkError || {};
  const isUnauthenticated = statusCode === HttpStatusCode.Unauthorized;

  // token expired && api return 401 && current page is protected pages
  if (isUnauthenticated) {
    store.dispatch(logout());
    clearLS();
    if (!unAuthRouters.includes(window.location.pathname)) {
      Navigate({ to: path.home });
    }
  }

  if (statusCode === HttpStatusCode.Forbidden) {
    store.dispatch(setShowMaintenance(true));
    clearLS();
    store.dispatch(logout());
    return;
  }

  // for dev debug, global request error will show in console
  if ((process.env.REACT_APP_NODE_ENV as string) === 'development') {
    console.log('[GraphQL error]', graphQLErrors);
    console.log('[Network error]', networkError);
  }
});

const authLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = getAccessTokenFromLS();

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Accept: 'application/json',
      authorization: token ? `Bearer ${token}` : '',
    },
  }));

  // return the headers to the context so httpLink can read them
  return forward(operation);
});

export const client = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
  connectToDevTools: (process.env.REACT_APP_NODE_ENV as string) === 'development' ? true : false,
});
