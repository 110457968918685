import { createAction, createReducer } from '@reduxjs/toolkit';
import { User } from 'src/types/auth.type';
import { getAccessTokenFromLS } from '../utils/auth';

interface AuthState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  user: any;
  token: string;
  refresh: boolean;
  notiMoney: boolean;
  moneyAnim: boolean;
  first_time_login_modal: string;
}

const initialState: AuthState = {
  user: null,
  token: getAccessTokenFromLS(),
  refresh: false,
  notiMoney: false,
  moneyAnim: false,
  first_time_login_modal: '',
};

export const login = createAction('login', function (user: User) {
  return {
    payload: {
      ...user,
    },
  };
});

export const setOpenAuthModal = createAction(
  'setOpenAuthModal',
  function (typeModal: 'login' | 'register') {
    return { payload: { typeModal } };
  },
);

export const setCloseAuthModal = createAction('setCloseAuthModal');

export const logout = createAction('logout');

export const setToken = createAction('setToken', function (token: string) {
  return { payload: { token } };
});

export const setRefresh = createAction('setRefresh', function (data: boolean) {
  return {
    payload: data,
  };
});

export const setNotiMoney = createAction('setNotiMoney', function (data: boolean) {
  return {
    payload: data,
  };
});

export const setForceHiddenRegister = createAction(
  'setForceHiddenRegister',
  function (data: boolean) {
    return {
      payload: data,
    };
  },
);

export const setMoneyAnim = createAction('setMoneyAnim', function (data: boolean) {
  return {
    payload: data,
  };
});

export const setSportsMoney = createAction('setSportsMoney', function (data: number) {
  return {
    payload: data,
  };
});

export const setFirstTimeLoginModal = createAction(
  'setFirstTimeLoginModal',
  function (first_time_login_modal: string) {
    return {
      payload: first_time_login_modal,
    };
  },
);

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(login, (state, action) => {
      state.user = {
        ...action.payload,
      };
    })
    .addCase(logout, (state) => {
      state.user = null;
      state.token = '';
    })
    .addCase(setToken, (state, action) => {
      state.token = action.payload.token;
    })
    .addCase(setRefresh, (state, action) => {
      state.refresh = action.payload;
    })
    .addCase(setNotiMoney, (state, action) => {
      state.refresh = action.payload;
    })
    .addCase(setMoneyAnim, (state, action) => {
      state.moneyAnim = action.payload;
    })
    .addCase(setSportsMoney, (state, action) => {
      state.user.mSportsMoney = Math.round(action.payload);
    })
    .addCase(setFirstTimeLoginModal, (state, action) => {
      state.first_time_login_modal = action.payload;
    });
});

export default authReducer;
