/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Suspense } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router';

function ProtectedRoute() {
  const token = localStorage.getItem('token');
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   const user = useSelector((state: RootState) => state.auth.user);

  return <Suspense>{!token ? <Navigate to={'/login'} /> : <Outlet />}</Suspense>;
}

export default ProtectedRoute;
